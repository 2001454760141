export const TITLE_VISA_BENEFITS = {
  en_US: `These are your Visa benefits`,
  es_ES: `Estos son tus beneficios Visa`,
  pt_BR: `Esses são seus benefícios Visa`,
};

export const EMPTY_BENEFITS = {
  en_US:
    'There is no benefit available for the Visa product selected, please contact the issuing bank for more information or learn more about Visa products.',
  es_ES:
    'No hay ningún beneficio disponible para el producto Visa seleccionado, comunícate con el banco emisor para obtener más información u obtener más información sobre los productos Visa.',
  pt_BR:
    'Não existe benefício disponível para o produto Visa selecionado, por favor contate o banco emissor para mais informações ou conheça mais produtos Visa.',
};

export const VIEW_MORE = {
  en_US: 'View more',
  pt_BR: 'Ver mais',
  es_ES: 'Ver más',
};
