import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import styles from './Tag.module.scss';

type TagType = 'Default' | 'Highlighted';

export interface TagProps {
  label: string;
  type?: TagType;
}

const getTypeStyle = (type: TagType) => {
  switch (type) {
    case 'Default':
      return styles.default;
    case 'Highlighted':
      return styles.highlighted;
    default:
      return styles.default;
  }
};

const Tag = ({ label, type = 'Default' }: TagProps) => {
  const { getGlobalTheme } = useTheme();

  const theme = getGlobalTheme();
  const typeStyle = getTypeStyle(type);

  return (
    <div className={`${styles[theme]} ${styles.tagContainer} ${typeStyle}`}>
      <span className={styles[theme]}>{label}</span>
    </div>
  );
};

export default Tag;
