import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { Buffer } from 'buffer';
import Instruction from './Instruction/Instruction';
import DocumentIcon from '../../../assets/iconsV2/DocumentIcon';
import FaceIcon from '../../../assets/iconsV2/FaceIcon';
import ProfileSuccessIcon from '../../../assets/iconsV2/ProfileSuccessIcon';
import { useIntl } from '../../../intl';
import * as translations from './intl';
import styles from './IdentityConfirmation.module.scss';
import Button from '../../../componentsV2/Button/Button';
import FileUploader from '../../../componentsV2/FileUploader/FileUploader';
import { accountRecoveryService, cmxService } from '../../../services';
import { StateContext } from '../../../components/StateContextParent/StateContextParent';
import ModalInformation from '../../../components/ModalInformation/ModalInformation';
import {
  extensions,
  fileExtensions,
} from '../../../utils/fileExtensionsValidade';
import { AppInsightTrackContext } from '../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import { AccountRecoveryEmailNotAccessible } from '../../../@types/APIs/AccountRecovery';
import { removeNonNumeric } from '../../../utils/stringUtils';

interface Props {
  setStep: Dispatch<SetStateAction<number>>;
  formValues: any;
}

const IdentityConfirmation = ({ setStep, formValues }: Props) => {
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const [file, setFile] = useState<File>(null);
  const [fileCmxId, setFileCmxId] = useState<string>('');
  const { actions } = useContext(StateContext);

  const { translate } = useIntl();
  const intl = translate(translations);

  const getFileToDownload = async () => {
    try {
      const { data } = await cmxService.getDocumentCmx(fileCmxId);
      const { file: cmxFile } = data;
      const { name: document_name } = file;

      const fileExtension = fileExtensions.find(obj =>
        String(document_name).includes(obj.Extension.toLowerCase())
      );

      const bufferFile = Buffer.from(cmxFile as string, 'base64');
      const fileObj = new File([bufferFile], document_name, {
        type: fileExtension.MIME_Type,
      });

      return fileObj;
    } catch (error) {
      actions.modal.showModal(
        false,
        true,
        <ModalInformation
          type="error"
          message={intl.UNEXPECTED_ERROR_DOWNLOAD}
        />
      );
    }
  };

  const uploadFileCmx = async (file: File) => {
    const { name: document_name, type: content_type, size } = file;
    trackEventUserAction(
      '#### (ACCOUNT RECOVERY FLOW) #### - INICIOU O UPLOAD DO ARQUIVO ',
      {
        document_name,
        content_type,
        size,
      }
    );
    const reader = new FileReader();
    reader.readAsDataURL(file);
    await new Promise(resolve => {
      reader.onload = resolve;
    });
    const content_data = String(reader.result).substring(
      String(reader.result).indexOf(',') + 1
    );

    const sizeKbFile = Number(Number(size / 1024).toFixed(2));
    const sizeMBFile = Number(Number(sizeKbFile / 1024).toFixed(2));
    //check size minor or equall to 1.5MB

    if (sizeKbFile > 1536) {
      trackEventUserAction(
        '#### (ACCOUNT RECOVERY FLOW) #### - TAMANHO DO ARQUIVO MAIOR DO QUE O PERMITIDO ',
        {
          document_name,
          content_type,
          sizeKbFile,
        }
      );
      actions.modal.showModal(
        false,
        true,
        <ModalInformation
          type="error"
          message={intl.FILE_SIZE_ERROR}
          subtitle={`${document_name} (${sizeMBFile} MB)`}
        />
      );
      throw new Error('FILE_SIZE_ERROR');
    }

    if (!content_type || extensions.indexOf(content_type) < 0) {
      trackEventUserAction(
        '#### (ACCOUNT RECOVERY FLOW) #### - FORMATO DO ARQUIVO NÃO PERMITIDO ',
        {
          document_name,
          content_type,
        }
      );
      actions.modal.showModal(
        false,
        true,
        <ModalInformation
          type="error"
          message={intl.FILE_TYPE_ERROR}
          subtitle={`${document_name} (${sizeMBFile} MB)`}
        />
      );
      throw new Error('FILE_TYPE_ERROR');
    }

    const fileData = {
      content_data,
      content_type,
    };

    trackEventUserAction(
      '#### (ACCOUNT RECOVERY FLOW) #### - ARQUIVO SERÁ ENVIADO PARA O CMX ',
      {
        fileData,
      }
    );

    try {
      const responseCMX = await cmxService.uploadDocument(fileData);
      trackEventUserAction(
        '#### (ACCOUNT RECOVERY FLOW) #### - ARQUIVO SALVO NO CMX ',
        {
          dataCMX: responseCMX && responseCMX.data ? responseCMX.data : null,
        }
      );
      setFileCmxId(responseCMX.data);
    } catch (error) {
      actions.modal.showModal(
        false,
        true,
        <ModalInformation
          type="error"
          message={intl.UNEXPECTED_ERROR_OCCURRED}
          subtitle={`${document_name} (${sizeMBFile} MB)`}
        />
      );
      throw error;
    }
  };

  const handleNextStep = async () => {
    try {
      actions.modal.showLoading();
      const formattedBody = formatAccountRecovery();

      await accountRecoveryService.createAccountRecoveryRequest(formattedBody);

      actions.modal.closeModal();
      setStep(3);
    } catch (error) {
      actions.modal.hideLoading();
      actions.modal.showModal(
        false,
        true,
        <ModalInformation
          type="error"
          message={intl.UNEXPECTED_ERROR_OCCURRED}
        />
      );
      throw error;
    }
  };

  const formatAccountRecovery = (): AccountRecoveryEmailNotAccessible => {
    const formatted = {
      type: 'OLD_EMAIL_NOT_ACCESSIBLE',
      first_name: formValues.person.first_name,
      last_name: formValues.person.last_name,
      birth_date: formValues.person.birth_date,
      registrations: [formValues.person_registrations],
      phone: {
        phone_type: formValues.phone.phone_type,
        number: formValues.phone.number,
        international_prefix: formValues.phone.international_prefix,
      },
      email: formValues.lostEmail,
      new_email: formValues.currentEmail,
      attachments: [
        {
          document_type_name: 'National ID',
          document_name: file.name,
          external_attachment_id: fileCmxId,
        },
      ],
    };

    return formatted;
  };

  return (
    <div className={styles.container}>
      <ul className={styles.instructionsList}>
        <Instruction
          Icon={DocumentIcon}
          title={intl.INSTRUCTION_1_TITLE}
          description={intl.INSTRUCTION_1_DESCRIPTION}
        />
        <Instruction
          Icon={FaceIcon}
          title={intl.INSTRUCTION_2_TITLE}
          description={intl.INSTRUCTION_2_DESCRIPTION}
        />
        <Instruction
          Icon={ProfileSuccessIcon}
          title={intl.INSTRUCTION_3_TITLE}
          description={intl.INSTRUCTION_3_DESCRIPTION}
        />
      </ul>

      <div className={styles.uploadArea}>
        <FileUploader
          title={intl.UPLOAD_TITLE}
          description={intl.UPLOAD_DESCRIPTION}
          callbackAddFile={async (file: File) => {
            await uploadFileCmx(file);
            setFile(file);
          }}
          callbackDeleteFile={async () => {
            setFile(null);
            return true;
          }}
          getFileToDownload={getFileToDownload}
        />
      </div>

      {file && (
        <footer>
          <Button
            onClick={handleNextStep}
            label={intl.LABEL_BUTTON}
            trailingIcon="Chevron Right"
            className={styles.actionNextStep}
          />
        </footer>
      )}
    </div>
  );
};

export default IdentityConfirmation;
