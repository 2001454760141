/* eslint-disable no-console */
import { useContext } from 'react';
import { IntlContext } from '../../../../intl';
import * as translations from '../../../../components/Sections/AllBenefits/BenefitsCategories/intl';
import CardsList from './CardsList/CardsList';
import { BenefitV2 } from '../../../../@types/APIs/benefit';
import { getTranslationByCategory } from '../../../../utils/benefits';

interface YourBenefitsListProps {
  benefits: BenefitV2[];
}

const YourBenefitsList = (props: YourBenefitsListProps) => {
  const { benefits } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const benefitsWithCategoriesTranslated =
    benefits &&
    benefits.map(value => {
      return {
        ...value,
        categories: getTranslationByCategory(value.categories, intl),
      };
    });

  return <CardsList benefitsList={benefitsWithCategoriesTranslated} />;
};

export default YourBenefitsList;
