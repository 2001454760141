export const GET_APP_STORE = {
  en_US: 'Get on App Store',
  pt_BR: 'Obtenha na App Store',
  es_ES: 'Obtenga en App Store',
};

export const GET_GOOGLE_PLAY = {
  en_US: 'Get on Google Play',
  pt_BR: 'Obtenha no Google Play',
  es_ES: 'Obtenga en Google Play',
};

export const TEXT_SEE = {
  en_US: 'View',
  pt_BR: 'Ver',
  es_ES: 'Ver',
};

export const TEXT_OPEN = {
  en_US: 'OPEN',
  pt_BR: 'ABRIR',
  es_ES: 'ABRIR',
};

export const TEXT_OPEN_BENEFIT_APP = {
  en_US: 'Open in the Visa Benefits app',
  pt_BR: 'Abrir no app Visa Benefit',
  es_ES: 'Abrir en la aplicación Visa Benefits',
};
