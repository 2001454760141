import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import Tag from '../Tag/Tag';
import Typography from '../Typography/Typography';
import styles from './Card.module.scss';

export interface CardProps {
  title: string;
  image: string;
  description: string;
  categories: string[];
  onClick: () => void;
}

const Card = ({
  title,
  image,
  description,
  categories,
  onClick,
}: CardProps) => {
  const { getGlobalTheme } = useTheme();
  const theme = getGlobalTheme();

  return (
    <button
      onClick={onClick}
      className={`${styles.cardContainer} ${styles[theme]}`}
    >
      <div
        className={styles.cardImage}
        style={{ background: `url(${image}) lightgray 50% / cover no-repeat` }}
      ></div>
      <div className={styles.cardContent}>
        <div className={styles.text}>
          <Typography variant="h6" color="primary" type="Heading 6 Semibold">
            {title}
          </Typography>
          <Typography
            variant="p"
            color="text-paragraph"
            type="Body Small Paragraph Regular"
          >
            {description}
          </Typography>

          <ul className={styles.tagList}>
            {categories.map(category => (
              <li key={category}>
                <Tag label={category} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </button>
  );
};

export default Card;
