import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import styles from './IndemnityArea.module.scss';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import { StateContext } from '../../../../../../../../components/StateContextParent/StateContextParent';

const IndemityArea = () => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const { utils } = useContext(StateContext);
  const card = utils.getSelectedCard();

  const country = utils.getCountry();
  const isMexico = country === 'MEXICO';

  return (
    <div
      className={`${styles.IndemityArea}`}
      data-testid="DivTripDelayIndemityArea"
    >
      <label className={`${styles.labelTitle} ${styles[themes]}`}>
        {card.name === 'Signature' && isMexico
          ? intl.INDEMNITY_AMOUNT_SIGNATURE_MEXICO
          : card.name == 'Platinum' && isMexico
          ? intl.INDEMNITY_AMOUNT_PLATINUM_MEXICO
          : card.name === 'Infinite'
          ? intl.INDEMNITY_AMOUNT_INFINITE
          : intl.INDEMNITY_AMOUNT_SIGNATURE}
      </label>
    </div>
  );
};

export default IndemityArea;
