export const INDEMNITY_AMOUNT_INFINITE = {
  en_US:
    'If the documentation provided is complete and correct, the amount to be reimbursed will be up to USD 3,000 per insured passenger, which will be paid in local currency. This amount is applicable to Visa Infinite cards.',
  pt_BR:
    'Se a documentação fornecida estiver completa e correta, o valor a ser reembolsado será de até USD 4.500 para viagens internacionais e até R$ 14.850 para viagens nacionais por passageiro assegurado. Este valor é aplicável aos cartões Visa Infinite.',
  es_ES:
    'Si la documentación proporcionada es completa y correcta, el monto a reembolsar será de hasta USD 3.000 por pasajero asegurado, que se pagará en moneda local. Este monto es aplicable a las tarjetas Visa Infinite.',
};

export const INDEMNITY_AMOUNT_SIGNATURE = {
  en_US: '',
  pt_BR:
    'Se a documentação fornecida estiver completa e correta, o valor a ser reembolsado será de até USD 3.000 para viagens internacionais e até R$ 9.900 para viagens nacionais por passageiro assegurado. Este valor é aplicável aos cartões Visa Signature.',
  es_ES: '',
};

export const INDEMNITY_AMOUNT_PLATINUM_MEXICO = {
  en_US: '',
  pt_BR: '',
  es_ES:
    'Si la documentación proporcionada es completa y correcta, el monto a reembolsar será de hasta USD 1.000 por pasajero asegurado, que se pagará en moneda local. Este monto es aplicable a las tarjetas Visa Platinum de México.',
};

export const INDEMNITY_AMOUNT_SIGNATURE_MEXICO = {
  en_US: '',
  pt_BR: '',
  es_ES:
    'Si la documentación proporcionada es completa y correcta, el monto a reembolsar será de hasta USD 2.000 por pasajero asegurado, que se pagará en moneda local. Este monto es aplicable a las tarjetas Visa Signature de México.',
};
