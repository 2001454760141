export const LABEL_EMAIL = {
  en_US: 'New email *',
  pt_BR: 'Novo e-mail *',
  es_ES: 'Nuevo correo electrónico *',
};

export const LABEL_CURRENT_EMAIL = {
  en_US: 'Current email',
  pt_BR: 'E-mail atual',
  es_ES: 'Correo electrónico actual',
};

export const EMAIL_INVALID_SAME_ACCOUNT = {
  en_US: 'This is the current email associate for this account.',
  pt_BR: 'Este é o e-mail atual associado a esta conta.',
  es_ES: 'Este es el correo electrónico actual asociado a esta cuenta.',
};

export const TEXT_FIELD_REQUIRED = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};

export const VALIDATE_EMAIL_VALID = {
  en_US: 'Please enter a valid e-mail.',
  pt_BR: 'Por favor, insira um e-mail válido.',
  es_ES: 'Por favor, introduzca un e-mail válido.',
};
