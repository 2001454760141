import { format, subDays } from 'date-fns';
import React from 'react';
import ModalInformation from '../../components/ModalInformation/ModalInformation';
import { userServices } from '../../services';

export const verifyUpdateEmail = async (
  utils,
  actions,
  intl,
  trackEventUserAction
) => {
  try {
    const { data: userInfo } = await userServices.getUserInfo();
    const { data: userSF } = await userServices.getCHInfo(utils.getCn());

    if (userInfo.email != userSF.email) {
      let userUpdate = { ...userSF, email: userInfo.email };
      userUpdate.person.birth_date =
        userUpdate.person.birth_date ||
        format(subDays(new Date(), 1), 'yyyy-MM-dd');
      userUpdate.Phone = { ...userUpdate.phone };
      delete userUpdate.phone;
      delete userUpdate.customer_id;
      delete userUpdate.partner_customer_id;

      trackEventUserAction(
        '#### (EMAIL CHANGE) E-MAIL DO USUÁRIO FOI ALTERADO',
        userInfo
      );

      actions.modal.showModal(
        false,
        false,
        <ModalInformation
          type="success"
          message={intl.EMAIL_CHANGE}
          subtitle={intl.EMAIL_UPDATE}
          textBtn={intl.OK}
          clickBtn={async () => {
            try {
              actions.modal.showLoading();
              trackEventUserAction(
                '#### (EMAIL CHANGE) USUÁRIO CLICOU EM OK E O E-MAIL SERÁ ATUALIZADO',
                userInfo
              );
              await userServices.updateCHInfo(utils.getCn(), userUpdate);
              trackEventUserAction(
                '#### (EMAIL CHANGE) E-MAIL DO USUÁRIO FOI ATUALIZADO',
                userInfo
              );
              actions.modal.hideLoading();
              actions.modal.closeModal();
            } catch (error) {
              trackEventUserAction(
                '#### (EMAIL CHANGE) ERRO AO ATUALIZAR E-MAIL DO USUÁRIO',
                {
                  error: error,
                  error_response: error && error.response ? error.response : '',
                }
              );
              actions.modal.hideLoading();
              actions.modal.showModal(
                false,
                true,
                <ModalInformation type="error" message={intl.ERROR_UPDATE} />,
                true,
                false
              );
            }
          }}
        />,
        true
      );
    }
  } catch (error) {}
};
