import { useContext } from 'react';
import TooltipIcon from '../../assets/icons/TooltipIcon';
import './Tooltip.scss';
import { IntlContext } from '../../intl';
import * as translations from './intl';

const Tooltip = ({ title, content, ...props }) => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  return (
    <div className="tooltip-container">
      <div className="tooltip-icon">
        <TooltipIcon
          axa={props.axa}
          monetization={props.monetization}
          aria-label={intl.TOOLTIP_ICON}
        />
      </div>
      <div className="tooltip">
        <div className="tooltip-title">{title}</div>
        <div className="tooltip-content">{content}</div>
      </div>
    </div>
  );
};

export default Tooltip;
