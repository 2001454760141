import { BenefitV2 } from '../@types/APIs/benefit';
import {
  CONCIERGE,
  LIFESTYLE,
  PURCHASE_PROTECTION,
  SPECIAL_BENEFIS,
  TRAVEL_BENEFITS,
} from './benefitCategory';

export const trip_assistance = 'Travel_Kit';
export const isTripAssistance = benefit =>
  benefit?.external_reference?.toLowerCase() === trip_assistance.toLowerCase();

export const findBenefitsByExternalReference = (claimType, benefits) => {
  return benefits.find(benefit => benefit.external_reference === claimType);
};

export const isVDBPBenefits = benefits => {
  const benefit = benefits[0];

  if (!benefit) {
    return false;
  }
  if (
    String(benefit.benefit_id).match(/[a-zA-Z]/g) &&
    typeof benefit.categories === 'string'
  ) {
    return false;
  }

  return true;
};

export const CINEPOLIS_GOLD = 'Cinepolis_Pass_169';
export const hasCinepolis = benefits =>
  benefits.find(benefit => benefit.benefit_id === CINEPOLIS_GOLD);

export const priorityOrder = (a: BenefitV2, b: BenefitV2) => {
  const priorityA = a.priority;
  const priorityB = b.priority;

  if (priorityA > priorityB) {
    return -1;
  }
  if (priorityA < priorityB) {
    return 1;
  }
  return 0;
};

export const getTranslationByCategory = (categories: string[], intl) => {
  const translatedCategories = [];

  categories.forEach(category => {
    switch (category) {
      case TRAVEL_BENEFITS:
        translatedCategories.push(
          intl.TITLE_BENEFITS_CATEGORY_TRAVEL_PROTECTION
        );
        break;
      case PURCHASE_PROTECTION:
        translatedCategories.push(
          intl.TITLE_BENEFITS_CATEGORY_PURCHASE_PROTECTION
        );
        break;
      case LIFESTYLE:
        translatedCategories.push(intl.TITLE_BENEFITS_CATEGORY_LIFESTYLE);
        break;
      case CONCIERGE:
        translatedCategories.push(intl.TITLE_BENEFITS_CATEGORY_CONCIERGE);
        break;
      case SPECIAL_BENEFIS:
        translatedCategories.push(intl.TITLE_BENEFITS_CATEGORY_SPECIAL);
        break;
      default:
        break;
    }
  });

  return translatedCategories;
};
