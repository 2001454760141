export const EMAIL_CHANGE = {
  en_US: 'You email has been successfully changed!',
  es_ES: '¡Tu correo electrónico ha sido cambiado con éxito!',
  pt_BR: 'E-mail redefinido com sucesso!',
};

export const EMAIL_UPDATE = {
  en_US: `Your email has been updated in your personal profile.`,
  es_ES: `Tu correo electrónico ha sido actualizado en tu perfil personal.`,
  pt_BR: `Seu e-mail foi atualizado em seu perfil pessoal.`,
};

export const OK = {
  en_US: 'Ok',
  es_ES: 'OK',
  pt_BR: 'Ok',
};

export const ERROR_UPDATE = {
  en_US: 'Error updating your personal information. Please try again later.',
  pt_BR:
    'Erro ao atualizar suas informações pessoais. Por favor, tente novamente mais tarde.',
  es_ES:
    'Error al actualizar tu información personal. Por favor, inténtalo de nuevo más tarde.',
};
