import { createElement, PropsWithChildren } from 'react';
import { ThemeColorsObject } from '../../themes/ThemeContextParent/themes';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import styles, { TypographyType } from './styles';

export const TagsHTML = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'p',
  'span',
  'caption',
  'label',
  'small',
] as const;

export type ITagHTML = typeof TagsHTML[number];

export interface TypographyProps extends PropsWithChildren {
  type: TypographyType;
  variant: ITagHTML;
  color: keyof ThemeColorsObject;
}

const Typography = ({
  type = 'Body Paragraph Medium',
  color = 'primary',
  variant = 'p',
  children,
}: TypographyProps) => {
  const { getCurrentThemeColors } = useTheme();
  const colors = getCurrentThemeColors();

  const selectedColor = colors[color];
  const typographyType = styles[type];

  return createElement(
    variant,
    {
      style: { color: selectedColor, ...typographyType },
    },
    children
  );
};

export default Typography;
