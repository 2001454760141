export const CHANGE_EMAIL = {
  en_US: 'Change email',
  es_ES: 'Cambiar el correo electrónico',
  pt_BR: 'Redefinir e-mail',
};

export const BODY_TEXT = {
  en_US: 'Do you want to change your email? Click below to change it.',
  es_ES:
    '¿Quieres cambiar tu correo electrónico? Haga clic a continuación para cambiarlo.',
  pt_BR: 'Você quer mudar seu e-mail? Clique abaixo para alterar.',
};

export const TEXT_EDIT_EMAIL = {
  en_US: 'Change e-mail',
  pt_BR: 'Redefinir e-mail',
  es_ES: 'Cambiar el correo electrónico',
};

export const BTN_EDIT = {
  en_US: 'Change e-mail',
  pt_BR: 'Redefinir e-mail',
  es_ES: 'Cambiar el correo electrónico',
};

export const BTN_SAVE = {
  en_US: 'Save',
  pt_BR: 'Salvar',
  es_ES: 'Guardar',
};

export const BTN_CANCEL = {
  en_US: 'Cancel',
  pt_BR: 'Cancelar',
  es_ES: 'Cancelar',
};

export const TEXT_EMAIL_CHANGE_SUCCESS = {
  en_US: 'You requested to change your email.',
  pt_BR: 'Você solicitou a redefinição do seu e-mail.',
  es_ES: 'Tu solicitaste el cambio de tu correo electrónico.',
};

export const SUBTITLE_EMAIL_CHANGE_SUCCESS = {
  en_US: 'Please check your new email inbox to complete the change.',
  pt_BR:
    'Por favor, verifique a caixa de entrada do seu novo e-mail para concluir a mudança.',
  es_ES:
    'Por favor, revisa la bandeja de entrada de tu nuevo correo electrónico para completar el cambio.',
};

export const ERROR = {
  en_US: 'Error updating your email. Please try again later.',
  pt_BR: 'Erro ao atualizar seu e-mail. Por favor, tente novamente mais tarde.',
  es_ES:
    'Error al actualizar tu correo electrónico. Por favor, inténtalo de nuevo más tarde.',
};
