export const INSTRUCTION_1_TITLE = {
  en_US: 'Visible name and photo ',
  pt_BR: 'Nome e foto visíveis',
  es_ES: 'Nombre y foto visibles',
};

export const INSTRUCTION_1_DESCRIPTION = {
  en_US: 'Ensure the document clearly shows your photo and name.',
  pt_BR: 'Certifique-se de que o documento mostre claramente sua foto e nome.',
  es_ES: 'Asegúrate de que el documento muestre claramente tu foto y nombre.',
};

export const INSTRUCTION_2_TITLE = {
  en_US: 'Keep you face visible',
  pt_BR: 'Deixe o rosto visível',
  es_ES: 'Haz visible tu cara',
};

export const INSTRUCTION_2_DESCRIPTION = {
  en_US: 'Do not use accessories like hats, sunglasses, or masks.',
  pt_BR: 'Não use acessórios como bonés, óculos de sol ou máscara.',
  es_ES: 'No uses accesorios como gorras, lentes de sol o máscaras.',
};

export const INSTRUCTION_3_TITLE = {
  en_US: 'Choose a well-lit place',
  pt_BR: 'Procure um local iluminado',
  es_ES: 'Elija un lugar iluminado',
};

export const INSTRUCTION_3_DESCRIPTION = {
  en_US: 'Choose a well-lit place, free from shadows or direct sunlight',
  pt_BR: 'Escolha um local bem iluminado para tirar a foto.',
  es_ES: 'Elija un lugar bien iluminado, sin sombras ni luz solar directa.',
};

export const LABEL_BUTTON = {
  en_US: 'Submit photo',
  pt_BR: 'Enviar foto',
  es_ES: 'Enviar foto',
};

export const UPLOAD_TITLE = {
  en_US: 'Upload photo',
  pt_BR: 'Escolha sua foto',
  es_ES: 'Elegir foto',
};

export const UPLOAD_DESCRIPTION = {
  en_US:
    'The accepted file formats are JPG, JPEG and PNG. The maximum file size is 1.5 MB.',
  pt_BR:
    'Os arquivos aceitos são JPG, JPEG e PNG. O tamanho máximo do arquivo é 1,5 MB.',
  es_ES:
    'Los archivos aceptados son JPG, JPEG y PNG. El tamaño máximo del archivo es de 1,5 MB.',
};

export const UNEXPECTED_ERROR_OCCURRED = {
  en_US:
    'An error occurred while performing this operation. Please try again later.',
  pt_BR:
    'Ocorreu um erro ao efetuar esta operação. Por favor tente novamente mais tarde.',
  es_ES:
    'Se ha producido un error al realizar esta operación. Por favor, inténtelo de nuevo más tarde.',
};

export const UNEXPECTED_ERROR_DOWNLOAD = {
  en_US: 'An unexpected error has occurred.',
  pt_BR: 'Ocorreu um erro inesperado.',
  es_ES: 'Ha ocurrido un error inesperado.',
};

export const FILE_SIZE_ERROR = {
  es_ES: 'Todos los archivos deben tener menos de 1.5 MB.',
  en_US: 'All files must be below 1.5 MB.',
  pt_BR: 'Todos os arquivos devem ser abaixo de 1.5 MB.',
};

export const FILE_TYPE_ERROR = {
  en_US: 'Invalid file format.',
  pt_BR: 'Formato do arquivo é inválido.',
  es_ES: 'Formato de archivo inválido.',
};
