/* eslint-disable no-unused-vars */
const moduleBanr = account => {
  const sequenceAccount = [3, 2, 4, 7, 6, 5, 4, 3, 2];
  const sumAccount = calculateSequence(account, sequenceAccount);
  const result = sumAccount % 11;

  if (result === 0) {
    return 0;
  } else if (result === 1) {
    return 6;
  }

  return 11 - result;
};

const calculateSequence = (numbers, sequence) => {
  let sum = 0;
  for (var i = 0; i < numbers.length; i++) {
    sum += parseInt(numbers[i]) * sequence[i];
  }

  return sum;
};

const agencyNumberIsValid = agencyNumber => {
  return /^[0-9]{1,5}$/.test(agencyNumber) && parseInt(agencyNumber) > 0;
};

const accountNumberLength = () => {
  return 11;
};

const agencyNumberLength = () => {
  return 4;
};

/**
 * Receive account and agency with '-'
 * Agency format: xxxx-xx
 * Account format: xxxxxxxxx-x
 */
export default () => ({
  account: (code, agency = null) => {
    if (!code || code.length !== accountNumberLength()) return false;

    let arrayCode = code.split('-');
    let numbers = arrayCode[0].split('');
    let dv = arrayCode[1];

    const calculatedNumber = moduleBanr(numbers);

    return (
      calculatedNumber.toString().toUpperCase() === dv.toString().toUpperCase()
    );
  },
  agency: code => {
    if (!code || code.length !== agencyNumberLength()) return false;
    return agencyNumberIsValid(code);
  },
  accountNumberLength: code => {
    if (!code) return false;
    return code.length === accountNumberLength();
  },
  agencyNumberLength: code => {
    if (!code) return false;
    return code.length === agencyNumberLength();
  },
  getAgencyNumberLength: () => {
    return agencyNumberLength();
  },
  getAccountNumberLength: () => {
    return accountNumberLength();
  },
  getMaskAgencyName: () => {
    return 'maskBanrisulBranchCode';
  },
  getMaskAccountName: () => {
    return 'maskBanrisulAccountNumber';
  },
  isGenericValidation: () => {
    return false;
  },
  getFormatAccount: () => {
    return '000000000-0';
  },
});
