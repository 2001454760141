import { useState, useContext, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './BenefitsGrid.module.scss';
import Button from '../../../../componentsV2/Button/Button';
import BenefitItem from './BenefitItem/BenefitItem';
import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import UpgradeMe from '../../../UpgradeMe/UpgradeMe';
import { enableUpselling } from '../../../../utils/verifyAccess';
import { enableCrosselling } from '../../../../utils/verifyAccessCrosselling';
import ShortBannerAXAMarket from '../../../../pages/Home/bannerAXAMarket/ShortBannerAXAMarket';
import { hasCinepolis, priorityOrder } from '../../../../utils/benefits';
import BannerCinepolis from '../../../BannerCinepolis/BannerCinepolis';
import { BenefitV2 } from '../../../../@types/APIs/benefit';

const itemGrid = (value, index, product) => (
  <BenefitItem key={index} value={value} product={product} />
);

const bannerTravel = intl => {
  return (
    <div className={styles.banner}>
      <span>{intl.TEXT_TRAVEL}</span>
    </div>
  );
};

const bannerCotizar = (config, history) => {
  return (
    <div className={styles.bannerUpselling}>
      <div className={styles.textUpselling}>
        <h2>{config.title}</h2>
        <h3>{config.subtitle}</h3>
      </div>
      <div className={styles.button}>
        <Button
          type="Secondary"
          surface="Inverse"
          onClick={() => history.push(config.link)}
          label={config.textButton}
        />
      </div>
    </div>
  );
};

const createConfigBannerCotizar = (intl, country, productName, history) => {
  if (enableCrosselling(productName, country)) {
    const configCrosseling = {
      title: intl.TEXT_TITLE_CROSSELLING,
      subtitle: intl.TEXT_SUBTITLE_CROSSELLING,
      textButton: intl.TEXT_BUTTON_CROSSELLING,
      link: '/cross-sell-preview/EMC_Emergency_Medical_Assistance',
    };
    return bannerCotizar(configCrosseling, history);
  }
  if (enableUpselling(productName, country)) {
    const configUpselling = {
      title: intl.TEXT_TITLE_UPSELLING,
      subtitle: intl.TEXT_SUBTITLE_UPSELLING,
      textButton: intl.TEXT_BUTTON_UPSELLING,
      link: '/upsell-preview/EMC_Emergency_Medical_Assistance',
    };
    return bannerCotizar(configUpselling, history);
  }
  return false;
};

const createBannerBenefit = (intl, country, productName, history) => {
  const enableCotizar = createConfigBannerCotizar(
    intl,
    country,
    productName,
    history
  );
  return enableCotizar ? enableCotizar : bannerTravel(intl);
};

interface BenefitsGridProps {
  benefits?: BenefitV2[];
  product?: string;
  productName?: string;
  history?: any;
}

const BenefitsGrid = (props: BenefitsGridProps) => {
  const { benefits, product, productName, history } = props;
  const [limit, setLimit] = useState(9);
  const { translate, country } = useContext(IntlContext);
  const intl = translate(translations);
  const benefitsCommom = benefits.filter(benefit => benefit.priority === 0);
  const benefitsPriority = benefits
    .filter(benefit => benefit.priority > 0)
    .sort(priorityOrder);
  const existsMore = limit < benefitsCommom.length;
  const emptyBenefits = benefits.length === 0;
  const cinepolis = useMemo(() => hasCinepolis(benefits), [benefits]);

  const createGridPriority =
    benefitsPriority &&
    benefitsPriority.map((value, index) => itemGrid(value, index, product));

  const createGridCommom =
    benefitsCommom &&
    benefitsCommom
      .slice(0, limit)
      .map((value, index) => itemGrid(value, index, product));

  const loadMore = () => {
    setLimit(limit + 9);
  };

  return (
    <div className={styles.container} data-testid="benefitsGridTestId">
      <div>
        <div className={styles.wrapBanner}>
          <ShortBannerAXAMarket />
        </div>

        <ul className={styles.priorityGrid}>{createGridPriority}</ul>

        {emptyBenefits || !productName ? (
          <div className={styles.card}>
            <div className={styles.emptyBenefits}>{intl.EMPTY_BENEFITS}</div>
            <UpgradeMe />
          </div>
        ) : (
          <>
            {cinepolis ? (
              <BannerCinepolis cinepolisBenefit={cinepolis} />
            ) : (
              createBannerBenefit(intl, country, productName, history)
            )}
          </>
        )}

        <ul className={styles.commonGrid}>{createGridCommom}</ul>
      </div>

      {existsMore && (
        <div className={styles.btnArea}>
          <Button
            type="Secondary"
            onClick={loadMore}
            id="benefits-loadMore"
            label={intl.LOAD_MORE}
          />
        </div>
      )}
    </div>
  );
};

export default withRouter(BenefitsGrid);
