import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { HEADER_PRIVATE } from '../../components/Header/headerConfig';
import styles from './MainLayout.module.scss';
import { getHeaderConfigByTheme } from '../../components/Header/headerConfig';
import { StateContext } from '../../components/StateContextParent/StateContextParent';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { default as NavigationMobile } from '../../components/Mobile/Navigation/Navigation';
import NotificationBinExpired from '../../components/ToastNotifications/NotificationBinExpired/NotificationBinExpired';
import { getQueryVariable } from '../../utils/uri';
import { ToastContainer, Slide } from 'react-toastify';
import RedBarMessage from '../../components/RedBarMessage/RedBarMessage';
import { ENABLE_RED_BAR_MESSAGE } from '../../utils/environments_variables';
import AppBanner from '../../components/ToastNotifications/NotificationOpenApp/AppBanner';

const MainLayout = ({ children, headerType = HEADER_PRIVATE, location }) => {
  const { state } = useContext(StateContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const [headerTypeConfig, setHeadertypeConfig] = useState();
  const [loading, setLoading] = useState(true);
  const [isMobileApp, setIsMobileApp] = useState(false);

  const isRedBarMessageEnable = ENABLE_RED_BAR_MESSAGE;

  const theme = getGlobalTheme();
  const isLogged = state.user.logged;
  const emptyHeader = isLogged && state.cards.data.length === 0;
  const [appBannerVisible, setAppBannerVisible] = useState(false);

  useEffect(() => {
    const headerConfig = getHeaderConfigByTheme(theme);
    setHeadertypeConfig(headerConfig[headerType]);
    setLoading(false);
  }, [theme, headerType]);

  useEffect(() => {
    const isMobileApp = getQueryVariable('mobileApp');
    if (isMobileApp && isMobileApp === 'true') {
      setIsMobileApp(true);
    }
  }, [location]);

  return (
    <>
      {loading ? null : (
        <>
          {!isMobileApp && (
            <div className={styles.header}>
              <Header headerType={headerTypeConfig} emptyHeader={emptyHeader} />
            </div>
          )}
          {isRedBarMessageEnable && (
            <div className={styles.alertArea}>
              <RedBarMessage />
            </div>
          )}
          <AppBanner setAppBannerVisible={setAppBannerVisible} />
          <ToastContainer
            enableMultiContainer
            autoClose={false}
            transition={Slide}
            className={
              appBannerVisible ? styles.toastContainerWithAppBanner : undefined
            }
          />
          <div className="notClosable">
            {isLogged && <NotificationBinExpired />}
          </div>
          <main
            className={styles.container}
            style={{ marginTop: !headerTypeConfig.backgroundColor ? -80 : 0 }}
          >
            {children}
          </main>
          {!isMobileApp && (
            <>
              <NavigationMobile />
              <Footer />
            </>
          )}
        </>
      )}
    </>
  );
};

export default withRouter(MainLayout);
