export const EXEMPLE = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_RELATIONSHIP_CARD_HOLDER = {
  en_US: 'Relationship to cardholder',
  pt_BR: 'Relação com o titular do cartão',
  es_ES: 'Relación con el titular de la tarjeta',
};

export const TEXT_IM_CH = {
  en_US: 'I am the cardholder',
  pt_BR: 'Eu sou o titular do cartão',
  es_ES: 'Yo soy el titular de la tarjeta',
};

export const TEXT_RELATIONSHIP_VALUE_SPOUSE = {
  en_US: 'Spouse',
  pt_BR: 'Cônjuge',
  es_ES: 'Cónyuge',
};

export const TEXT_RELATIONSHIP_VALUE_OTHER = {
  en_US: 'Other',
  pt_BR: 'Outro',
  es_ES: 'Otro',
};

export const TEXT_RELATIONSHIP_DEPENDENT_CHILD = {
  en_US: 'Beneficiary child',
  pt_BR: 'Filho',
  es_ES: 'Hijo',
};

export const LABEL_NAME_AFFECTED = {
  en_US: 'Affected person name *',
  pt_BR: 'Nome da pessoa afetada *',
  es_ES: 'Nombre de la persona afectada *',
};

export const LABEL_LAST_NAME_AFFECTED = {
  en_US: 'Affected person last name *',
  pt_BR: 'Sobrenome da pessoa afetada *',
  es_ES: 'Apellido de la persona afectada *',
};

export const TEXT_OTHER = {
  en_US: 'Other (explain) *',
  pt_BR: 'Outro (explicar) *',
  es_ES: 'Otro (explicar) *',
};

export const LABEL_CARDHOLDER_CHECKBOX = {
  en_US: 'Relationship between the affected person and the cardholder *',
  pt_BR: 'Relação da pessoa afetada com o titular do cartão *',
  es_ES: 'Relación entre el afectado y el tarjetahabiente *',
};

export const DISCLAIMER_CHECKBOX_CARDHOLDER = {
  en_US:
    'The affected person must always be the cardholder, therefore, it is prohibited to appoint a third party as the affected person.',
  pt_BR:
    'A pessoa afetada deve sempre ser o titular do cartão, sendo assim, é vetado indicar terceiros como pessoa afetada.',
  es_ES:
    'El afectado debe ser siempre el titular de la tarjeta, por lo que está prohibido designar a un tercero como afectado.',
};

export const CARDHOLDER_DISCLAIMER = {
  en_US:
    'We remind you that the claim must be initiated by the holder of the card used for the purchase of the ticket(s), that is, the person whose name appears on the Visa card.',
  pt_BR:
    'Lembramos que o sinistro deve ser iniciado pelo titular do cartão utilizado na compra da(s) passagem(s), ou seja, a pessoa cujo nome consta no cartão Visa.',
  es_ES:
    'Recordamos que el reclamo debe ser iniciado por el titular de la tarjeta utilizada para la compra del/los pasaje(s), es decir, la persona cuyo nombre aparece en la tarjeta Visa.',
};
