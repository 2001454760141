import { CSSProperties } from 'react';

export type TypographyType = keyof typeof styles;

const defaultParams = {
  fontStyle: 'normal',
  margin: 0,
} as CSSProperties;

// 1rem = 10px

const styles = {
  'Heading 1 Light': {
    ...defaultParams,
    lineHeight: '150%',
    fontWeight: 300,
    fontSize: '4.8rem',
    fontFamily: 'visa_dialect_light',
  } as CSSProperties,
  'Heading 1 Regular': {
    ...defaultParams,
    lineHeight: '150%',
    fontWeight: 400,
    fontSize: '4.8rem',
    fontFamily: 'visa_dialect_regular',
  } as CSSProperties,
  'Heading 1 Medium': {
    ...defaultParams,
    lineHeight: '150%',
    fontWeight: 500,
    fontSize: '4.8rem',
    fontFamily: 'visa_dialect_medium',
  } as CSSProperties,
  'Heading 1 Semibold': {
    ...defaultParams,
    lineHeight: '150%',
    fontWeight: 600,
    fontSize: '4.8rem',
    fontFamily: 'visa_dialect_semibold',
  } as CSSProperties,
  'Heading 1 Bold': {
    ...defaultParams,
    lineHeight: '150%',
    fontWeight: 700,
    fontSize: '4.8rem',
    fontFamily: 'visa_dialect_bold',
  } as CSSProperties,

  'Heading 2 Light': {
    ...defaultParams,
    lineHeight: '150%',
    fontWeight: 300,
    fontSize: '4rem',
    fontFamily: 'visa_dialect_light',
  } as CSSProperties,
  'Heading 2 Regular': {
    ...defaultParams,
    lineHeight: '150%',
    fontWeight: 400,
    fontSize: '4rem',
    fontFamily: 'visa_dialect_regular',
  } as CSSProperties,
  'Heading 2 Medium': {
    ...defaultParams,
    lineHeight: '150%',
    fontWeight: 500,
    fontSize: '4rem',
    fontFamily: 'visa_dialect_medium',
  } as CSSProperties,
  'Heading 2 Semibold': {
    ...defaultParams,
    lineHeight: '150%',
    fontWeight: 600,
    fontSize: '4rem',
    fontFamily: 'visa_dialect_semibold',
  } as CSSProperties,
  'Heading 2 Bold': {
    ...defaultParams,
    lineHeight: '150%',
    fontWeight: 700,
    fontSize: '4rem',
    fontFamily: 'visa_dialect_bold',
  } as CSSProperties,

  'Heading 3 Light': {
    ...defaultParams,
    lineHeight: '150%',
    fontWeight: 300,
    fontSize: '3.2rem',
    fontFamily: 'visa_dialect_light',
  } as CSSProperties,
  'Heading 3 Regular': {
    ...defaultParams,
    lineHeight: '150%',
    fontWeight: 400,
    fontSize: '3.2rem',
    fontFamily: 'visa_dialect_regular',
  } as CSSProperties,
  'Heading 3 Medium': {
    ...defaultParams,
    lineHeight: '150%',
    fontWeight: 500,
    fontSize: '3.2rem',
    fontFamily: 'visa_dialect_medium',
  } as CSSProperties,
  'Heading 3 Semibold': {
    ...defaultParams,
    lineHeight: '150%',
    fontWeight: 600,
    fontSize: '3.2rem',
    fontFamily: 'visa_dialect_semibold',
  } as CSSProperties,
  'Heading 3 Bold': {
    ...defaultParams,
    lineHeight: '150%',
    fontWeight: 700,
    fontSize: '3.2rem',
    fontFamily: 'visa_dialect_bold',
  } as CSSProperties,

  'Heading 4 Light': {
    ...defaultParams,
    lineHeight: '120%',
    fontWeight: 300,
    fontSize: '2.8rem',
    fontFamily: 'visa_dialect_light',
  } as CSSProperties,
  'Heading 4 Regular': {
    ...defaultParams,
    lineHeight: '120%',
    fontWeight: 400,
    fontSize: '2.8rem',
    fontFamily: 'visa_dialect_regular',
  } as CSSProperties,
  'Heading 4 Medium': {
    ...defaultParams,
    lineHeight: '120%',
    fontWeight: 500,
    fontSize: '2.8rem',
    fontFamily: 'visa_dialect_medium',
  } as CSSProperties,
  'Heading 4 Semibold': {
    ...defaultParams,
    lineHeight: '120%',
    fontWeight: 600,
    fontSize: '2.8rem',
    fontFamily: 'visa_dialect_semibold',
  } as CSSProperties,
  'Heading 4 Bold': {
    ...defaultParams,
    lineHeight: '120%',
    fontWeight: 700,
    fontSize: '2.8rem',
    fontFamily: 'visa_dialect_bold',
  } as CSSProperties,

  'Heading 5 Light': {
    ...defaultParams,
    lineHeight: '120%',
    fontWeight: 300,
    fontSize: '2.4rem',
    fontFamily: 'visa_dialect_light',
  } as CSSProperties,
  'Heading 5 Regular': {
    ...defaultParams,
    lineHeight: '120%',
    fontWeight: 400,
    fontSize: '2.4rem',
    fontFamily: 'visa_dialect_regular',
  } as CSSProperties,
  'Heading 5 Medium': {
    ...defaultParams,
    lineHeight: '120%',
    fontWeight: 500,
    fontSize: '2.4rem',
    fontFamily: 'visa_dialect_medium',
  } as CSSProperties,
  'Heading 5 Semibold': {
    ...defaultParams,
    lineHeight: '120%',
    fontWeight: 600,
    fontSize: '2.4rem',
    fontFamily: 'visa_dialect_semibold',
  } as CSSProperties,
  'Heading 5 Bold': {
    ...defaultParams,
    lineHeight: '120%',
    fontWeight: 700,
    fontSize: '2.4rem',
    fontFamily: 'visa_dialect_bold',
  } as CSSProperties,

  'Heading 6 Light': {
    ...defaultParams,
    lineHeight: '120%',
    fontWeight: 300,
    fontSize: '2rem',
    fontFamily: 'visa_dialect_light',
  } as CSSProperties,
  'Heading 6 Regular': {
    ...defaultParams,
    lineHeight: '120%',
    fontWeight: 400,
    fontSize: '2rem',
    fontFamily: 'visa_dialect_regular',
  } as CSSProperties,
  'Heading 6 Medium': {
    ...defaultParams,
    lineHeight: '120%',
    fontWeight: 500,
    fontSize: '2rem',
    fontFamily: 'visa_dialect_medium',
  } as CSSProperties,
  'Heading 6 Semibold': {
    ...defaultParams,
    lineHeight: '120%',
    fontWeight: 600,
    fontSize: '2rem',
    fontFamily: 'visa_dialect_semibold',
  } as CSSProperties,
  'Heading 6 Bold': {
    ...defaultParams,
    lineHeight: '120%',
    fontWeight: 700,
    fontSize: '2rem',
    fontFamily: 'visa_dialect_bold',
  } as CSSProperties,

  'Body Large Paragraph Light': {
    ...defaultParams,
    lineHeight: '150%',
    fontWeight: 300,
    fontSize: '1.8rem',
    fontFamily: 'visa_dialect_light',
  } as CSSProperties,
  'Body Large Paragraph Regular': {
    ...defaultParams,
    lineHeight: '150%',
    fontWeight: 400,
    fontSize: '1.8rem',
    fontFamily: 'visa_dialect_regular',
  } as CSSProperties,
  'Body Large Paragraph Medium': {
    ...defaultParams,
    lineHeight: '150%',
    fontWeight: 500,
    fontSize: '1.8rem',
    fontFamily: 'visa_dialect_medium',
  } as CSSProperties,
  'Body Large Paragraph Semibold': {
    ...defaultParams,
    lineHeight: '150%',
    fontWeight: 600,
    fontSize: '1.8rem',
    fontFamily: 'visa_dialect_semibold',
  } as CSSProperties,
  'Body Large Paragraph Bold': {
    ...defaultParams,
    lineHeight: '150%',
    fontWeight: 700,
    fontSize: '1.8rem',
    fontFamily: 'visa_dialect_bold',
  } as CSSProperties,

  'Body Paragraph Light': {
    ...defaultParams,
    lineHeight: '150%',
    fontWeight: 300,
    fontSize: '1.6rem',
    fontFamily: 'visa_dialect_light',
  } as CSSProperties,
  'Body Paragraph Regular': {
    ...defaultParams,
    lineHeight: '150%',
    fontWeight: 400,
    fontSize: '1.6rem',
    fontFamily: 'visa_dialect_regular',
  } as CSSProperties,
  'Body Paragraph Medium': {
    ...defaultParams,
    lineHeight: '150%',
    fontWeight: 500,
    fontSize: '1.6rem',
    fontFamily: 'visa_dialect_medium',
  } as CSSProperties,
  'Body Paragraph Semibold': {
    ...defaultParams,
    lineHeight: '150%',
    fontWeight: 600,
    fontSize: '1.6rem',
    fontFamily: 'visa_dialect_semibold',
  } as CSSProperties,
  'Body Paragraph Bold': {
    ...defaultParams,
    lineHeight: '150%',
    fontWeight: 700,
    fontSize: '1.6rem',
    fontFamily: 'visa_dialect_bold',
  } as CSSProperties,

  'Body Small Paragraph Light': {
    ...defaultParams,
    lineHeight: '150%',
    fontWeight: 300,
    fontSize: '1.4rem',
    fontFamily: 'visa_dialect_light',
  } as CSSProperties,
  'Body Small Paragraph Regular': {
    ...defaultParams,
    lineHeight: '150%',
    fontWeight: 400,
    fontSize: '1.4rem',
    fontFamily: 'visa_dialect_regular',
  } as CSSProperties,
  'Body Small Paragraph Medium': {
    ...defaultParams,
    lineHeight: '150%',
    fontWeight: 500,
    fontSize: '1.4rem',
    fontFamily: 'visa_dialect_medium',
  } as CSSProperties,
  'Body Small Paragraph Semibold': {
    ...defaultParams,
    lineHeight: '150%',
    fontWeight: 600,
    fontSize: '1.4rem',
    fontFamily: 'visa_dialect_semibold',
  } as CSSProperties,
  'Body Small Paragraph Bold': {
    ...defaultParams,
    lineHeight: '150%',
    fontWeight: 700,
    fontSize: '1.4rem',
    fontFamily: 'visa_dialect_bold',
  } as CSSProperties,

  'Caption Light': {
    ...defaultParams,
    lineHeight: '120%',
    fontWeight: 300,
    fontSize: '1.2rem',
    fontFamily: 'visa_dialect_light',
  } as CSSProperties,
  'Caption Regular': {
    ...defaultParams,
    lineHeight: '120%',
    fontWeight: 400,
    fontSize: '1.2rem',
    fontFamily: 'visa_dialect_regular',
  } as CSSProperties,
  'Caption Medium': {
    ...defaultParams,
    lineHeight: '120%',
    fontWeight: 500,
    fontSize: '1.2rem',
    fontFamily: 'visa_dialect_medium',
  } as CSSProperties,
  'Caption Semibold': {
    ...defaultParams,
    lineHeight: '120%',
    fontWeight: 600,
    fontSize: '1.2rem',
    fontFamily: 'visa_dialect_semibold',
  } as CSSProperties,
  'Caption Bold': {
    ...defaultParams,
    lineHeight: '120%',
    fontWeight: 700,
    fontSize: '1.2rem',
    fontFamily: 'visa_dialect_bold',
  } as CSSProperties,
};

export default styles;
