import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import Input from '../../../../../../../../components/Input/Input';
import styles from '../BaggageDelay.module.scss';
import * as translations from './intl';
import * as constants from '../consts';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';

const PersonalArea = props => {
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const intl = translate(translations);
  const themes = getGlobalTheme();

  const { values, handleChange, touched, handleBlur, errors } = props;

  return (
    <div className={`${styles.incidentArea}`}>
      <label className={`${styles.labelTitle} ${styles[themes]}`}>
        {intl.CARDHOLDER_DISCLAIMER}
      </label>

      <div className="row">
        <div className={`col-12 col-sm-10 col-md-6`}>
          <Input
            label={intl.LABEL_NAME_AFFECTED}
            type="text"
            name="affected_person.person.first_name"
            disabled={
              values.affected_person.relationship ===
              constants.CARD_HOLDER_VALUE
            }
            value={values.affected_person.person.first_name}
            onChange={handleChange}
            touched={
              touched.affected_person &&
              touched.affected_person.person &&
              touched.affected_person.person.first_name
            }
            onBlur={handleBlur}
            error={
              errors.affected_person &&
              errors.affected_person.person &&
              errors.affected_person.person.first_name
            }
            maxLength="40"
          />
        </div>
        <div className={`col-12 col-sm-10 col-md-6`}>
          <Input
            label={intl.LABEL_LAST_NAME_AFFECTED}
            type="text"
            disabled={
              values.affected_person.relationship ===
              constants.CARD_HOLDER_VALUE
            }
            name="affected_person.person.last_name"
            value={values.affected_person.person.last_name}
            onChange={handleChange}
            touched={
              touched.affected_person &&
              touched.affected_person.person &&
              touched.affected_person.person.last_name
            }
            onBlur={handleBlur}
            error={
              errors.affected_person &&
              errors.affected_person.person &&
              errors.affected_person.person.last_name
            }
            maxLength="80"
          />
        </div>
      </div>
    </div>
  );
};

export default PersonalArea;
