export default (env, axios, errorHandler) => ({
  uploadDocument: async data => {
    const url = '/cmx/document/upload';
    return errorHandler(axios.post(url, data), 'uploadDocument', {
      data,
    });
  },

  saveIdCMX: async (customer_id, data, idiom) => {
    const headers = {
      'Accept-Language': idiom,
    };
    const url = `/api/specific/visagateway/v3/customers/${customer_id}/attachments`;

    return errorHandler(axios.post(url, data, { headers }), 'saveIdCMX', {
      data,
      customer_id,
      idiom,
    });
  },

  deleteDocument: async (customer_id, attachment_id, idiom) => {
    const headers = {
      'Accept-Language': idiom,
    };
    const url = `/api/specific/visagateway/v3/customers/${customer_id}/attachments/${attachment_id}`;

    return errorHandler(axios.delete(url, { headers }), 'deleteDocument', {
      attachment_id,
      customer_id,
      idiom,
    });
  },

  getExternalIdPersonalDocument: async (customer_id, attachment_id) => {
    const url = `/api/specific/visagateway/v3/customers/${customer_id}/attachments/${attachment_id}`;

    return errorHandler(axios.get(url), 'getExternalIdPersonalDocument', {
      attachment_id,
      customer_id,
    });
  },
  getDocumentCmx: async cmx_id => {
    const url = `/cmx/document/${cmx_id}`;
    return errorHandler(axios.get(url), 'downloadDocument');
  },
});
