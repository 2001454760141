/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './PublicBenefits.module.scss';
import Products from './products/Products';
import { productsAndBenefitsServices } from '../../services';
import { IntlContext } from '../../intl';
import AllBenefits from '../../components/Sections/AllBenefits/AllBenefits';
import { useParams } from 'react-router-dom';
import {
  ALL,
  TRAVEL_BENEFITS,
  PURCHASE_PROTECTION,
  LIFESTYLE,
  CONCIERGE,
  SPECIAL_BENEFIS,
  HIDDEN,
} from '../../utils/benefitCategory';
import RelatedOffers from '../../components/RelatedOffers/RelatedOffers';
import KnowBenefits from '../../components/Sections/KnowBenefits/KnowBenefits';
import { PublicContext } from '../../components/PublicContextParent/PublicContextParent';
import RelatedSpecialBenefits from '../../components/RelatedSpecialBenefits/RelatedSpecialBenefits';

import {
  ENABLE_OFFERS,
  ENABLE_SPECIAL_BENEFITS,
  HIDDEN_BENEFITS,
} from '../../utils/environments_variables';
import Footer from '../DownloadApp/Footer/Footer';
import { checkAccessDownloadApp } from '../../utils/downloadAppAccess';
import { BenefitV2 } from '../../@types/APIs/benefit';

function getCategory() {
  const urlCategory = window.location.href.split('#')[1];
  if (urlCategory === TRAVEL_BENEFITS) {
    return TRAVEL_BENEFITS;
  } else if (urlCategory === CONCIERGE) {
    return CONCIERGE;
  } else if (urlCategory === PURCHASE_PROTECTION) {
    return PURCHASE_PROTECTION;
  } else if (urlCategory === LIFESTYLE) {
    return LIFESTYLE;
  } else {
    return ALL;
  }
}

// retrieve all benefits. depends to cardSelected
const loadBenefits = async (
  product,
  idiom,
  setBenefits,
  setLoadingBenefits
) => {
  try {
    setLoadingBenefits(true);
    const { data } = await productsAndBenefitsServices.getBenefitsByProductV2(
      product,
      idiom,
      false,
      false
    );
    let benefits = [...data] as BenefitV2[];

    if (ENABLE_SPECIAL_BENEFITS) {
      benefits = benefits.filter(
        benefit => !benefit.categories.includes(SPECIAL_BENEFIS)
      );
    }

    benefits = benefits.filter(
      benefit =>
        !benefit.categories.includes(HIDDEN) &&
        !HIDDEN_BENEFITS.includes(benefit.benefit_code)
    );

    setBenefits(benefits);
  } catch (error) {
    setBenefits([]);
  }
  setLoadingBenefits(false);
};

const loadCards = async (
  country,
  idiom,
  setCards,
  setCardSelected,
  setLoadingCards,
  product_id,
  setIndexSelected,
  history,
  productActive
) => {
  try {
    setLoadingCards(true);
    const { data } = await productsAndBenefitsServices.getProductsByCountry(
      country,
      idiom,
      false
    );
    const cardsFilter = data;
    let cardSelected = cardsFilter[0];
    setCards(cardsFilter);
    if (product_id) {
      const findProduct = data.filter(product => {
        return product.product_id === product_id;
      })[0];
      if (findProduct) {
        cardSelected = findProduct;
      } else {
        history.push('/benefits');
      }
    } else if (productActive && productActive.product_id) {
      const findProduct = data.filter(product => {
        return product.product_id === productActive.product_id;
      })[0];
      if (findProduct) cardSelected = findProduct;
    }

    const card =
      productActive.product_id && !cardSelected ? cardsFilter[0] : cardSelected;
    const findIndex = cardsFilter.findIndex(
      cardIndex => cardIndex.product_id === card.product_id
    );
    setIndexSelected(findIndex);
    setCardSelected(card || {});
    setLoadingCards(false);
  } catch (error) {
    setLoadingCards(false);
    setCardSelected({});
  }
};

const PublicBenefits = ({ history }) => {
  const { product_id } = useParams<{ product_id: string }>();
  const { idiom, country, idiomForApi } = useContext(IntlContext);
  const { productActive, setProductActive } = useContext(PublicContext);
  const [cards, setCards] = useState([]);
  const [loadingCards, setLoadingCards] = useState(true);
  const [loadingBenefits, setLoadingBenefits] = useState(true);
  const [indexSelected, setIndexSelected] = useState('finding');
  const [benefits, setBenefits] = useState<BenefitV2[]>([]);
  const [benefitsFiltered, setBenefitsFiltered] = useState<BenefitV2[]>([]);
  const [category, setCategory] = useState('');

  useEffect(() => {
    loadCards(
      country,
      idiomForApi(),
      setCards,
      setProductActive,
      setLoadingCards,
      product_id,
      setIndexSelected,
      history,
      productActive
    );
  }, [country, idiom]);

  useEffect(() => {
    if (productActive && productActive.product_id) {
      loadBenefits(
        productActive.product_id,
        idiomForApi(),
        setBenefits,
        setLoadingBenefits
      );
    }
  }, [productActive, idiom]);

  useEffect(() => {
    filterBenefits(getCategory());
  }, [benefits]);

  // filter benefits by category
  const filterBenefits = value => {
    setCategory(value);

    if (value === ALL) return setBenefitsFiltered(benefits);

    const filtered =
      benefits &&
      benefits.filter(benefit => {
        return benefit.categories.includes(value);
      });
    setBenefitsFiltered(filtered);
  };

  return (
    <div className={styles.container}>
      <Products
        cards={cards}
        cardSelected={productActive}
        setCardSelected={setProductActive}
        loadingCards={loadingCards}
        indexSelected={indexSelected}
      />
      <AllBenefits
        category={category}
        loadingBenefits={loadingBenefits}
        benefitsFiltered={benefitsFiltered}
        filterBenefits={filterBenefits}
        benefitList={benefits}
        product={
          productActive && productActive.product_id
            ? productActive.product_id
            : null
        }
        productName={
          productActive && productActive.name ? productActive.name : null
        }
      />
      <div className={styles.boxPage}>
        {checkAccessDownloadApp(country) && <Footer />}
        {ENABLE_OFFERS && <RelatedOffers currentOfferId={undefined} />}
        {ENABLE_SPECIAL_BENEFITS && <RelatedSpecialBenefits />}
      </div>
      <KnowBenefits />
    </div>
  );
};

export default withRouter(PublicBenefits);
