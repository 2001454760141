import { useContext } from 'react';
import { IntlContext } from '../../../../../intl/index';
import { ThemeContext } from '../../../../../themes/ThemeContextParent/ThemeContextParent';
import { dataLayerContentMenuTrack } from '../../../../../utils/GTM_helper';
import { useHistory } from 'react-router-dom';
import Card from '../../../../../componentsV2/Card/Card';
import { BenefitV2 } from '../../../../../@types/APIs/benefit';
import * as categoriesTranslations from '../../../../../components/Sections/AllBenefits/BenefitsCategories/intl.js';
import { getTranslationByCategory } from '../../../../../utils/benefits';

const AFLUENT = 'theme-afluent';

interface BenefitItemProps {
  value: BenefitV2;
  product: string;
}

const BenefitItem = (props: BenefitItemProps) => {
  const { product, value } = props;
  const { translate } = useContext(IntlContext);
  const categoriesIntl = translate(categoriesTranslations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const history = useHistory();

  const bgcolorByTheme = theme === AFLUENT ? ' #021E4C' : '#1434CB';

  const imageAPI =
    value &&
    value.images &&
    value.images.find(image => image.type === 'PICTURE');
  const backGroundImage = imageAPI ? imageAPI.content_url : bgcolorByTheme;
  const benefit = value.benefit_code.replace(/_/g, ' ');

  const categories = getTranslationByCategory(value.categories, categoriesIntl);

  return (
    <li>
      <Card
        title={value.name}
        image={backGroundImage}
        description={value.short_description}
        categories={categories}
        onClick={() => {
          history.push(`/benefits/${product}/${value.benefit_id}`);
          dataLayerContentMenuTrack(
            `Click on card ${benefit} - CTA View more`,
            'Benefits'
          );
        }}
      />
    </li>
  );
};

export default BenefitItem;
