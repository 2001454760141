export const CHANGE_PASSWORD = {
  en_US: 'Change password',
  es_ES: 'Cambiar la contraseña',
  pt_BR: 'Redefinir senha',
};

export const BODY_TEXT = {
  en_US: 'Do you want to change your password? Click below to change it.',
  es_ES:
    '¿Quieres cambiar tu contraseña? Haga clic a continuación para cambiarla.',
  pt_BR: 'Você quer mudar sua senha? Clique abaixo para redefinir.',
};

export const TITLE_TEXT = {
  en_US:
    'You will receive an email with instructions on how to reset your password. Upon resetting, your current password will be invalidated. Are you sure you want to continue?',
  es_ES:
    'Tú recibirás un correo electrónico con instrucciones sobre cómo restablecer tu contraseña. Al restablecerse, se invalidará tu contraseña actual. ¿Desea continuar?',
  pt_BR:
    'Você receberá um e-mail com instruções sobre como redefinir sua senha. Ao redefinir, sua senha atual será invalidada. Tem certeza de que deseja continuar?',
};

export const CONFIRM_TEXT = {
  en_US: 'Yes, I want to change it',
  es_ES: 'Sí, quiero restablecerla',
  pt_BR: 'Sim, quero redefinir',
};

export const DENY_TEXT = {
  en_US: 'No',
  es_ES: 'No',
  pt_BR: 'Não',
};

export const SUCCESS_CHANGE_PASSWORD_TEXT = {
  en_US:
    'Congratulations! Your current password was successfully invalidated. Please check your email and follow the instructions to reset your new password.',
  es_ES:
    '¡Felicidades! Tu contraseña actual fue invalidada con éxito. Revisa tu correo electrónico y siga las instrucciones para restablecer tu nueva contraseña.',
  pt_BR:
    'Parabéns! Sua senha atual foi invalidada com sucesso. Verifique seu e-mail e siga as instruções para redefinir sua nova senha.',
};

export const FAILURE_CHANGE_PASSWORD_TEXT = {
  en_US:
    'Unfortunately, we were not able to carry out your change of password.',
  es_ES: 'Desafortunadamente, no pudimos realizar tu cambio de contraseña.',
  pt_BR: 'Infelizmente, não conseguimos realizar a alteração da sua senha.',
};

export const TRY_AGAIN_TEXT = {
  en_US: 'Please try again',
  es_ES: 'Inténtalo de nuevo',
  pt_BR: 'Por favor, tente novamente',
};

export const TRY_LATER_TEXT = {
  en_US: 'Or try later',
  es_ES: 'O prueba mas tarde',
  pt_BR: 'Ou tente mais tarde',
};
