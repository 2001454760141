import Card from '../../../../../componentsV2/Card/Card';
import { useHistory } from 'react-router-dom';
import styles from './CardList.module.scss';
import { BenefitV2 } from '../../../../../@types/APIs/benefit';

interface CardListProps {
  benefitsList: BenefitV2[];
}

const CardsList = (props: CardListProps) => {
  const { benefitsList } = props;
  const history = useHistory();

  return (
    <ul className={`${styles.gridBenefits}`} data-testid="listCardContent">
      {benefitsList.map(benefit => {
        const image = benefit.images.find(image => image.type === 'PICTURE');
        return (
          <li key={benefit.name}>
            <Card
              title={benefit.name}
              description={benefit.short_description}
              image={image.content_url}
              categories={benefit.categories}
              onClick={() => {
                history.push(`/your-benefits/${benefit.benefit_id}`);
              }}
            />
          </li>
        );
      })}
    </ul>
  );
};

export default CardsList;
