import { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const TooltipIcon = props => {
  const { axa = false } = props;
  const { monetization = false } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const config_icon = getThemeIcon(theme, axa);

  const svgProps = { ...props };
  delete svgProps.axa;

  return monetization ? (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill={props.color || config_icon.primary}
      {...svgProps}
    >
      <path
        d="M8 15C4.13388 15 1 11.8661 1 8C1 4.13388 4.13388 1 8 1C11.8661 1 15 4.13388 15 8C15 11.8661 11.8661 15 8 15Z"
        stroke={props.color || config_icon.primary}
        strokeWidth={2}
      />
      <path d="M8 6L8 4" stroke={config_icon.white} strokeWidth={2} />
      <path d="M8 12L8 7" stroke={config_icon.white} strokeWidth={2} />
    </svg>
  ) : (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...svgProps}>
      <path
        d="M8 15C4.13388 15 1 11.8661 1 8C1 4.13388 4.13388 1 8 1C11.8661 1 15 4.13388 15 8C15 11.8661 11.8661 15 8 15Z"
        stroke={props.color || config_icon.primary}
        strokeWidth={2}
      />
      <path
        d="M8 6L8 4"
        stroke={props.color || config_icon.primary}
        strokeWidth={2}
      />
      <path
        d="M8 12L8 7"
        stroke={props.color || config_icon.primary}
        strokeWidth={2}
      />
    </svg>
  );
};

export default TooltipIcon;
