export const LABEL_FIRST_NAME = {
  en_US: 'Name',
  pt_BR: 'Nome',
  es_ES: 'Nombre',
};

export const LABEL_LAST_NAME = {
  en_US: 'Last name',
  pt_BR: 'Sobrenome',
  es_ES: 'Apellido',
};

export const LABEL_ADDRESS = {
  en_US: 'Home address',
  pt_BR: 'Endereço residencial',
  es_ES: 'Dirección',
};

export const LABEL_TITLE = {
  en_US: 'Title',
  pt_BR: 'Título',
  es_ES: 'Título',
};

export const LABEL_RELATIONSHIP = {
  en_US: 'Relationship',
  pt_BR: 'Parentesco',
  es_ES: 'Relación',
};

export const LABEL_EMAIL = {
  en_US: 'E-mail',
  pt_BR: 'E-mail',
  es_ES: 'Correo eletrónico',
};

export const LABEL_CURRENT_EMAIL = {
  en_US: 'Current e-mail',
  pt_BR: 'E-mail atual',
  es_ES: 'Correo eletrónico actual',
};

export const LABEL_NATIONALITY = {
  en_US: 'Nationality',
  pt_BR: 'Nacionalidade',
  es_ES: 'Nacionalidad',
};

export const LABEL_CPF = {
  en_US: 'CPF',
  pt_BR: 'CPF',
  es_ES: 'CPF',
};

export const LABEL_PASSPORT = {
  en_US: 'Passport',
  pt_BR: 'Passaporte',
  es_ES: 'Pasaporte',
};

export const LABEL_NATIONAL_ID = {
  en_US: 'National ID',
  pt_BR: 'Identidade Nacional',
  es_ES: 'Documento Nacional de Identidad',
};

export const LABEL_IDENTIFICATION = {
  en_US: 'Identification',
  pt_BR: 'Identificação',
  es_ES: 'Identificación',
};
export const RELATIONSHIP_SON = {
  en_US: 'Son',
  pt_BR: 'Filho',
  es_ES: 'Hijo',
};

export const RELATIONSHIP_DAU = {
  en_US: 'Daughter',
  pt_BR: 'Filha',
  es_ES: 'Hija',
};

export const RELATIONSHIP_SPOUSE = {
  en_US: 'Spouse',
  pt_BR: 'Cônjuge',
  es_ES: 'Cónyuge',
};

export const RELATIONSHIP_OTHER = {
  en_US: 'Other',
  pt_BR: 'Outro',
  es_ES: 'Otro',
};

export const LABEL_MISTER = {
  en_US: 'Mister',
  pt_BR: 'Senhor',
  es_ES: 'Señor',
};

export const LABEL_MS = {
  en_US: 'Ms',
  pt_BR: 'Senhora',
  es_ES: 'Señora',
};

export const LABEL_MISS = {
  en_US: 'Miss',
  pt_BR: 'Senhorita',
  es_ES: 'Señorita',
};

export const LABEL_DR = {
  en_US: 'Doctor',
  pt_BR: 'Doutor',
  es_ES: 'Doctor',
};

export const LABEL_PR = {
  en_US: 'Professor',
  pt_BR: 'Professor',
  es_ES: 'Maestra',
};

export const LABEL_BIRTH_DATE = {
  en_US: 'Date of birth',
  pt_BR: 'Data de nascimento',
  es_ES: 'Fecha de nacimiento',
};

export const LABEL_MALE = {
  en_US: 'Male',
  pt_BR: 'Masculino',
  es_ES: 'Masculino',
};

export const LABEL_FEMALE = {
  en_US: 'Female',
  pt_BR: 'Feminino',
  es_ES: 'Femenino',
};

export const LABEL_UNKNOWN = {
  en_US: 'Prefer not to inform',
  es_ES: 'Prefiero no informar',
  pt_BR: 'Prefiro não informar',
};

export const LABEL_GENDER = {
  en_US: 'Gender',
  pt_BR: 'Gênero',
  es_ES: 'Género',
};

export const LABEL_OCCUPATION = {
  en_US: 'Profession',
  pt_BR: 'Profissão',
  es_ES: 'Profesión',
};

export const VALUE_PHONE_TYPE_LN = {
  en_US: 'Landline',
  pt_BR: 'Fixo',
  es_ES: 'Fijo',
};

export const VALUE_PHONE_TYPE_MB = {
  en_US: 'Mobile',
  pt_BR: 'Celular',
  es_ES: 'Móvil',
};

export const TELEPHONE_NUMBER = {
  en_US: 'Telephone number',
  pt_BR: 'Número de telefone',
  es_ES: 'Número de teléfono',
};

export const TEXT_POLITICALLY_EXPOSED = {
  en_US: 'Considera-se uma pessoa politicamente exposta?',
  pt_BR: 'Considera-se uma pessoa politicamente exposta?',
  es_ES: 'Considera-se uma pessoa politicamente exposta?',
};

export const TEXT_YES = {
  en_US: 'Yes',
  es_ES: 'Sí',
  pt_BR: 'Sim',
};

export const TEXT_NO = {
  en_US: 'No',
  es_ES: 'No',
  pt_BR: 'Não',
};
