/* eslint-disable no-console */
/* eslint-disable no-console */
import { useContext, useState, useEffect } from 'react';

import BenefitsCategories from '../../../components/Sections/AllBenefits/BenefitsCategories/BenefitsCategories';
import { productsAndBenefitsServices } from '../../../services';
import { StateContext } from '../../../components/StateContextParent/StateContextParent';
import { IntlContext } from '../../../intl';
import * as translations from './intl';
import { ALL, CATEGORY_TAG_SUBSCRIPTION } from '../../../utils/benefitCategory';
import YourBenefitsList from './YourBenefitsList/YourBenefitsList';
import styles from './YourBenefits.module.scss';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import { ENABLE_SPECIAL_BENEFITS } from '../../../utils/environments_variables';
import Loading from '../../../components/Loading/Loading';
import { BenefitV2 } from '../../../@types/APIs/benefit';

const loadBenefits = async (
  binOrPan: string,
  idiom: string,
  setBenefits: (benefits: BenefitV2[]) => void,
  setBenefitsFiltered: (benefits: BenefitV2[]) => void,
  setLoadingBenefits: (value: boolean) => void,
  benefitList: BenefitV2[]
) => {
  try {
    setLoadingBenefits(true);
    let benefits: BenefitV2[] = [];
    if (benefitList && benefitList.length > 0) {
      benefits = benefitList;
    } else {
      const { data } = await productsAndBenefitsServices.getBenefitsByProductV2(
        binOrPan,
        idiom,
        false,
        false
      );

      benefits = [...data];
    }

    if (ENABLE_SPECIAL_BENEFITS) {
      benefits = benefits.filter(benefit =>
        benefit.categories.find(
          category => !CATEGORY_TAG_SUBSCRIPTION.includes(category)
        )
      );
    }

    benefits = benefits.filter(
      benefit => !benefit.benefit_code.includes('axa')
    );

    setBenefits(benefits);
    setBenefitsFiltered(benefits);
  } catch (error) {
    setBenefits([]);
    console.log(error);
  }

  setLoadingBenefits(false);
};

const YourBenefits = ({ initialCategory = ALL, benefitList, isLoading }) => {
  const { utils } = useContext(StateContext);
  const { idiomForApi, translate } = useContext(IntlContext);
  const intl = translate(translations);
  const [loadingBenefits, setLoadingBenefits] = useState(true);
  const [benefits, setBenefits] = useState<BenefitV2[]>([]);
  const [benefitsFiltered, setBenefitsFiltered] = useState([]);
  const [category, setCategory] = useState(initialCategory);
  const cardSelected = utils.getSelectedCard();
  const binOrPan = utils.getBin(true);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const filterBenefits = value => {
    setCategory(value);

    if (value === ALL) return setBenefitsFiltered(benefits);

    const filtered =
      benefits &&
      benefits.filter(benefit => {
        return benefit.categories.find(category => category.includes(value));
      });

    setBenefitsFiltered(filtered);
  };

  useEffect(() => {
    if (cardSelected && cardSelected.external_reference && !isLoading) {
      loadBenefits(
        binOrPan,
        idiomForApi(),
        setBenefits,
        setBenefitsFiltered,
        setLoadingBenefits,
        benefitList
      );
    }
    setCategory(initialCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardSelected, isLoading]);

  const emptyBenefits = benefitsFiltered.length === 0;

  return (
    <div className={`${styles[theme]} ${styles.yourBenefits}`}>
      <h2 className={styles[theme]}>{intl.TITLE_VISA_BENEFITS}</h2>
      <BenefitsCategories
        filterBenefits={filterBenefits}
        selected={category}
        isBenefitPage
        benefitList={benefitList}
      />
      {loadingBenefits || isLoading ? (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      ) : (
        <>
          {emptyBenefits ? (
            <div className={styles.emptyBenefits}>{intl.EMPTY_BENEFITS}</div>
          ) : (
            <YourBenefitsList benefits={benefitsFiltered} />
          )}
        </>
      )}
    </div>
  );
};

export default YourBenefits;
