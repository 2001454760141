import React from 'react';

import styles from './AllBenefits.module.scss';
import BenefitsCategories from './BenefitsCategories/BenefitsCategories';
import BenefitsGrid from './BenefitsGrid/BenefitsGrid';
import Loading from '../../Loading/Loading';

const AllBenefits = props => {
  const {
    benefitsFiltered,
    category,
    filterBenefits,
    product,
    loadingBenefits,
    productName,
    benefitList,
  } = props;

  return (
    <section id="mainContent" className={styles.allBenefits}>
      <div>
        <BenefitsCategories
          filterBenefits={filterBenefits}
          selected={category}
          isBenefitPage
          benefitList={benefitList}
        />
        {loadingBenefits ? (
          <div className={styles.loadingContainer}>
            <Loading />
          </div>
        ) : (
          <BenefitsGrid
            benefits={benefitsFiltered}
            product={product}
            productName={productName}
          />
        )}
      </div>
    </section>
  );
};

export default AllBenefits;
