import { useContext, useState, useEffect } from 'react';
import './AppBanner.scss';
import visaBenefitsLogo from '../../../assets/images/downloadApp/storeIcon/visaBenefitsLogo.png';
import { IntlContext } from '../../../intl';
import * as translations from './intl';
import { checkAccessDownloadApp } from '../../../utils/downloadAppAccess';
import { isAndroid } from 'react-device-detect';
import { differenceInDays, fromUnixTime, parseISO } from 'date-fns';

const TIME_BETWEEN_DISMISS_DAYS = 7;

const AppBanner = ({ setAppBannerVisible }) => {
  const { translate, country } = useContext(IntlContext);
  const intl = translate(translations);
  const [hasTimeBetweenDismissPassed, setHasTimeBetweenDismissPassed] =
    useState(false);
  const [isAppAvailable, setIsAppAvailable] = useState(false);

  const hide = () => {
    localStorage.setItem('app_banner_last_shown', new Date().toISOString());
    setHasTimeBetweenDismissPassed(false);
  };

  const handleShouldShow = () => {
    try {
      const lastShownString = localStorage.getItem('app_banner_last_shown');
      const lastShown = lastShownString
        ? parseISO(lastShownString)
        : fromUnixTime(0);

      if (differenceInDays(new Date(), lastShown) > TIME_BETWEEN_DISMISS_DAYS) {
        setHasTimeBetweenDismissPassed(true);
      }
    } catch (error) {
      setHasTimeBetweenDismissPassed(true);
    }
  };

  useEffect(() => {
    setIsAppAvailable(checkAccessDownloadApp(country));
  }, [country]);

  useEffect(() => {
    handleShouldShow();
  }, []);

  useEffect(() => {
    setAppBannerVisible?.(
      isAndroid && hasTimeBetweenDismissPassed && isAppAvailable
    );
  }, [hasTimeBetweenDismissPassed, isAndroid, isAppAvailable]);

  if (!isAndroid || !isAppAvailable || !hasTimeBetweenDismissPassed) {
    return null;
  }

  return (
    <div className="app-banner">
      <button className="app-banner-close" onClick={hide}>
        x
      </button>
      <div className="app-banner-content">
        <img
          src={visaBenefitsLogo}
          alt="Visa Benefits"
          className="app-banner-icon"
        />
        <div className="app-banner-text">
          <strong>Visa Benefits</strong>
          <p>{intl.TEXT_OPEN_BENEFIT_APP}</p>
        </div>
      </div>
      <button
        className="app-banner-button"
        onClick={() => {
          document.location =
            'com.visa.lac.benefits://com.visa.lac.benefits/home';
          setTimeout(() => {
            document.location = 'market://details?id=com.visa.lac.benefits';
            hide();
          }, 2000);
        }}
      >
        {intl.TEXT_OPEN}
      </button>
    </div>
  );
};

export default AppBanner;
