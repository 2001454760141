import { useContext, useEffect, useState } from 'react';
import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import styles from './BenefitsCategories.module.scss';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import { distinct } from '../../../../utils/array';
import { ALL } from '../../../../utils/benefitCategory';
import { getTranslationByCategory } from '../../../../utils/benefits';

const BenefitsCategories = props => {
  const { filterBenefits, selected, isBenefitPage, benefitList } = props;

  const { translate, idiom } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const [distinctCategories, setDistinctCategories] = useState<string[]>([]);

  const intl = translate(translations);
  const theme = getGlobalTheme();

  const mountTheCategoriesArray = () => {
    let allCategories = [];
    try {
      benefitList.forEach(benefit => {
        allCategories = allCategories.concat(benefit.categories);
      });
      const categoriesFormated = allCategories.filter(distinct);
      setDistinctCategories(categoriesFormated);
    } catch (err) {
      setDistinctCategories([]);
    }
  };

  useEffect(() => {
    mountTheCategoriesArray();
  }, [idiom, benefitList]);

  return (
    <div
      className={`${styles.benefitsCategories} ${
        isBenefitPage ? styles.isBenefit : ''
      }`}
    >
      <ul>
        <li
          onClick={() => filterBenefits(ALL)}
          className={
            selected === ALL
              ? `${styles[theme]} ${styles.active}`
              : `${styles[theme]}`
          }
        >
          {intl.TITLE_BENEFITS_CATEGORY_ALL}
        </li>
        {distinctCategories.map(category => {
          const categoriesTranslatedAndFormated = getTranslationByCategory(
            [category],
            intl
          );
          return (
            <li
              key={category}
              onClick={() => filterBenefits(category)}
              className={
                selected === category
                  ? `${styles[theme]} ${styles.active}`
                  : `${styles[theme]}`
              }
            >
              {categoriesTranslatedAndFormated[0]}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default BenefitsCategories;
